
// Import globals
@import './_variables.scss';
// Import all components styles
@import '../components/forms/style';
@import '../components/navbar/style';
@import '../components/table/style';

.container{
    width: 80% !important;
}

.selector {
    margin-top: 1em !important;
    display: block !important;
}

.ReactModal__Overlay{
    z-index: 9999;
    iframe{
        width: 100%;
        height: 100%;
    }
}
