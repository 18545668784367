.itemsForm {
    background-color: white;
    label{
        display: flex;
        span{
            margin-left: 12px;
        }
    }
}

.edit-bill-wrapper{
    label{
        display: flex;
        span{
            margin-left: 12px;
        }
    }
}
